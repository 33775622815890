import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-344876d2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "col-btn"
};
import { ref, computed } from 'vue';
import { cloneDeep } from 'lodash';
export default {
  __name: 'index',
  props: ['model', 'before', 'rules', 'btnSpan', 'border', 'labelWidth', 'resetFn', 'hideRequiredAsterisk'],
  emits: ['fetchList'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const formRef = ref();
    const props = __props;
    const hideRequiredAsterisk = computed(() => {
      return typeof props.hideRequiredAsterisk === 'boolean' ? props.hideRequiredAsterisk : true;
    });
    const $emit = __emit;
    const classes = computed(() => {
      return props.border ? 'border' : '';
    });
    const btnSpan = computed(() => {
      var _props$btnSpan;
      const num = Object.keys(props.model).length % 4;
      return (_props$btnSpan = props.btnSpan) !== null && _props$btnSpan !== void 0 ? _props$btnSpan : 24 - num * 6;
    });
    const fetchList = async resetFields => {
      if (resetFields) {
        formRef.value.resetFields();
        keepAliveData.value = {};
        if (props.resetFn) {
          props.resetFn();
          return;
        }
      } else {
        if (props.before) {
          const before = props.before();
          if (before && before.then) {
            before.then(res => {
              if (res !== false) {
                keepAliveData.value = cloneDeep(props.model);
                console.log(props.model, 'props.model');
                $emit('fetchList', {
                  data: cloneDeep(props.model),
                  reset: resetFields
                });
              }
            });
            return;
          } else if (before === false) {
            return;
          }
        }
      }
      keepAliveData.value = cloneDeep(props.model);
      $emit('fetchList', {
        data: cloneDeep(props.model),
        reset: resetFields
      });
    };

    // 用于缓存之前查询的字段
    const keepAliveData = ref({});
    const getKeepAliveData = () => {
      return keepAliveData.value;
    };
    __expose({
      getKeepAliveData,
      formRef,
      fetchList
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["advanced-query-layout-wrapper", classes.value])
      }, [_createVNode(_component_el_form, {
        ref_key: "formRef",
        ref: formRef,
        model: props.model,
        "label-width": props.labelWidth || 82,
        "label-position": "left",
        "hide-required-asterisk": hideRequiredAsterisk.value,
        rules: props.rules
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default"), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = $event => fetchList(false)),
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode("查询")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[1] || (_cache[1] = $event => fetchList(true)),
          plain: ""
        }, {
          default: _withCtx(() => [_createTextVNode("重置")]),
          _: 1
        })])]),
        _: 3
      }, 8, ["model", "label-width", "hide-required-asterisk", "rules"])], 2);
    };
  }
};